<template>
	<div style="background-color: #fff;min-height: 1000px;" v-loading='loading' v-if="ruleForm">
		<div v-if="ruleForm.type==3">
			<div style="padding: 30px 0;">
				<el-steps :active="active" align-center>
					<el-step title="买家申请换货"></el-step>
					<el-step title="卖家处理换货申请"></el-step>
					<el-step title="买家确认，换货成功"></el-step>
					<el-step title="下发换货商品"></el-step>
				</el-steps>
			</div>

			<div v-if="ruleForm.status==1">
				<div style="display: flex;align-items: center;color: #333;font-size: 16px;">
					<div style="margin-left: 200px;">待审单</div>
					<div style="margin-left: 20px;">
						<div>请处理换货申请，还剩{{durationDesc}}</div>
						<div style="margin-top: 20px;">如果同意，请发送换货地址给买家</div>
					</div>
				</div>
				<div style="margin-left: 240px;margin-top: 30px;">
					<el-button style="width:140px;" @click="sureApply(1)">拒绝换货申请</el-button>
					<el-button type="primary" style="width:140px;margin-left: 30px;" @click="sureApply(2)">同意换货申请</el-button>
				</div>
			</div>
			<!-- 待下发售后地址 -->
			<div v-if="ruleForm.status==3">
				<div style="display: flex;align-items: center;color: #333;font-size: 16px;">
					<div style="margin-left: 200px;">收货地址</div>
					<div style="margin-left: 20px;">
						<div style="display: flex;">
							<div style="width: 450px;border: 1px solid #666;margin-top: 20px;font-size: 14px;padding: 10px;">
								<div style="display: flex;align-items: center;">
									<div style="margin-right: 5px;">{{ruleForm.receiverName}}</div>
									|<div style="margin-left: 5px;">{{ruleForm.receiverPhone}}</div>
								</div>
								<div style="margin-top: 10px;">{{ruleForm.address}}</div>
							</div>
							<div style="color: #006EDD;margin-left: 10px;padding-top: 70px;font-size: 14px;" @click="showAddressChose">选择收货地址</div>
						</div>
					</div>
				</div>
				<div style="margin-left: 240px;margin-top: 30px;">
					<el-button type="primary" style="width:100px;" @click="sureDelivery">确认地址</el-button>
				</div>
			</div>
			<!-- 待卖家收货 -->
			<div v-if="ruleForm.status==4||ruleForm.status==5">
				<div style="display: flex;align-items: center;color: #333;font-size: 16px;">
					<div style="margin-left: 200px;">待卖家收货</div>
					<div style="margin-left: 20px;">
						<div v-if="ruleForm.status==4">待买家回寄商品</div>
						<div v-if="ruleForm.status==5" style="margin-top: 20px;">买家已退货，等待商家确认收货</div>
						<div style="margin-top: 20px;">{{ruleForm.receiverName}} {{ruleForm.receiverPhone}} {{ruleForm.address}}</div>
					</div>
				</div>
				<div style="margin-left: 240px;margin-top: 30px;">
					<el-button type="primary" style="width:100px;margin-left: 30px;" @click="sureShouhuo">确认收货</el-button>
				</div>
			</div>
			<div v-if="ruleForm.status==7">
				<div style="display: flex;align-items: center;color: #333;font-size: 16px;">
					<div style="margin-left: 200px;">换货成功</div>
					<div style="margin-left: 20px;">
						<div>售后完成</div>
						<div style="margin-top: 20px;">已给买家创建换货订单，本次售后换货结束</div>
						<div style="margin-top: 20px;">{{ruleForm.receiverName}} {{ruleForm.receiverPhone}} {{ruleForm.address}}</div>
					</div>
				</div>
			</div>
			<div v-if="ruleForm.status==8">
				<div style="display: flex;align-items: center;color: #333;font-size: 16px;">
					<div style="margin-left: 200px;">售后关闭</div>
					<div style="margin-left: 20px;">
						<div>换货关闭</div>
						<div style="margin-top: 20px;">客服拒绝了本次申请，拒绝理由：{{ruleForm.refuseReason}}</div>
					</div>
				</div>
			</div>
		</div>
		<div style="display: flex;justify-content: space-between;">
			<div class="refund-record">
				<div class="title">协商记录</div>
				<div class="refund-timeline">
					<el-timeline>
						<el-timeline-item v-for="(item, index) in ruleForm.consultList" :key="index" :timestamp="item.AddTime" placement="top">
							<el-card>
								<h4 v-if="item.type==2">商家<span style="margin-left: 10px;">{{item.createTime}}</span></h4>
								<h4 v-if="item.type==1">买家<span style="margin-left: 10px;">{{item.createTime}}</span></h4>
								<p style="word-wrap:break-word;">{{item.content}}</p>
							</el-card>
						</el-timeline-item>
					</el-timeline>
				</div>
			</div>
			<div style="border: 1px solid #333;margin-right: 200px;font-size: 14px;color: #333;height: max-content;padding-bottom: 10px;">
				<div style="background-color: #f5f5f5;padding: 10px 0;text-align: center;">售后申请</div>
				<div class="product-info">售后类型：{{ruleForm.type==1?'仅退款':(ruleForm.type==2?'退货退款':'换货')}}</div>
				<div class="product-info">退款金额：{{ruleForm.refundMoney}}</div>
				<div class="product-info">退款理由：{{ruleForm.applyReason}}</div>
				<div class="product-info">退款编号：{{ruleForm.refundCode}}</div>
				<div class="product-info">发起时间：{{ruleForm.createTime}}</div>
				<div class="product-info" v-if="ruleForm.realName">支付宝姓名：{{ruleForm.realName}}</div>
				<div class="product-info" v-if="ruleForm.alipayAccount">支付宝账号：{{ruleForm.alipayAccount}}</div>
				<div class="product-info" v-if="ruleForm.refundExplain">售后说明：{{ruleForm.refundExplain}}</div>
				<div class="product-info" style="display: flex;flex-direction: column;align-items: flex-start;" v-if="ruleForm.refundVoucher && ruleForm.refundVoucher.length">售后凭证：
					<div style="display: flex;width: 250px;flex-wrap: wrap;flex-direction: row;">
						<img @click="handleChangeImg(ruleForm.refundVoucher,index)" v-for="(item,index) in ruleForm.refundVoucher" :key="index"
						:src="item" alt="" style="width: 60px;height: 60px;margin: 10px;">
					</div>
				</div>
				<div v-if="ruleForm.list&&ruleForm.list.length">
					<div style="border-top: 1px solid #333;margin-top: 15px;padding-top: 15px;padding-left: 20px;padding-right: 20px;display: flex;align-items: center;"
					v-for="(item,index) in ruleForm.list" :key='index'>
						<img :src="item.skuImgUrl" alt="" style="width: 40px;height: 40px;">
						<div style="margin-left: 10px;">
							<div style="width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.goodsName}}</div>
							<div style="width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-top: 5px;">规格：{{item.specValues?item.specValues:'默认规格'}}</div>
						</div>
						<div style="margin-left: 20px;height: 40px;display: flex;flex-direction: column;justify-content: space-between;text-align: right;">
							<div style="color: #333;">{{item.salePrice}}</div>
							<div style="color: #999;">x{{item.quantity}}</div>
						</div>
					</div>
				</div>
				<div class="product-info">实收金额：￥{{ruleForm.payAmount||0}}</div>
				<div class="product-info">购买数量：{{ruleForm.quantity || 0}}件</div>
				<div class="product-info" v-if="ruleForm.isCoupon">使用优惠券：{{ruleForm.couponName}}</div>
				<div class="product-info">买家：{{ruleForm.buyerName}}</div>
				<div class="product-info">买家手机：{{ruleForm.buyerPhone}}</div>
				<div class="product-info" @click="toDetial()">订单编号：<span style="cursor:pointer;color:#409EFF;">{{ruleForm.orderNo}}</span></div>
				<div class="product-info">付款时间：{{ruleForm.payTime}}</div>
				<div class="product-info" style="display: flex;flex-direction: column;align-items: flex-start;" v-if="ruleForm.voucher && ruleForm.voucher.length">物流凭证：
					<div style="display: flex;width: 250px;flex-wrap: wrap;flex-direction: row;">
						<img @click="handleChangeImg(ruleForm.voucher,index)" v-for="(item,index) in ruleForm.voucher" :key="index" :src="item"
						alt="" style="width: 60px;height: 60px;margin: 10px;">
					</div>
				</div>
			</div>
		</div>
		
		<div style="color: #000000;font-size: 18px;margin-bottom: 20px;font-weight: bold;margin-top: 30px;margin-left: 20px;">
			售后备注
			<span style="margin-left:10px;color:#007aff;font-size:14px;" @click="ordermarkVisible = true;">添加备注</span>
		</div>
		<el-table :data="orderRemark" style="width: 1500px;margin-left: 20px;padding-bottom: 50px;">
			<el-table-column prop="remark" label="备注内容"></el-table-column>
			<el-table-column prop="operator" label="操作人"></el-table-column>
			<el-table-column prop="createTime" label="操作时间"></el-table-column>
		</el-table>

		<el-dialog title="审核拒绝" :visible.sync="ordermarkDialogVisible" width="650px" @close="remarkClose">
			<el-form>
				<el-form-item label="拒绝理由 :" label-width="120px" prop="orderMark">
					<el-input v-model="orderMark" maxlength="200" type="textarea" placeholder="请填写拒绝理由" style="width:300px;" clearable></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="ordermarkDialogVisible=false">关 闭</el-button>
				<el-button type="primary" @click="sureOrdermark()">确 认</el-button>
			</span>
		</el-dialog>

		<el-dialog title="选择收货地址" :visible.sync="addressDialogVisible" width="650px" @close="remarkClose">
			<div>
				<div style="color: #0066CC;" @click="toEditAddress">去新增地址</div>
				<el-radio v-model="radio" :label="index+1" v-for="(item,index) in addressList" :key="index" style="display: flex;align-items: center;">
					<div style="width: 570px;border: 1px solid #666;margin-top: 20px;font-size: 14px;padding: 10px;">
						<div>收件人：{{item.receiverName}}</div>
						<div style="margin-top: 10px;">联系电话：{{item.receiverPhone}}</div>
						<div style="margin-top: 10px;display: flex;align-items: center;justify-content: space-between;">
							<div style="width: 400px;">退货地址：{{item.province}}{{item.city}}{{item.area}}{{item.detailAddress}}</div>
							<div>邮政编码：{{item.addressCode}}</div>
						</div>
					</div>
				</el-radio>

			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addressDialogVisible=false">关 闭</el-button>
				<el-button type="primary" @click="addressMark()">确 认</el-button>
			</span>
		</el-dialog>
		<el-dialog title="确认回寄收货" :visible.sync="shouhuoDialogVisible" width="650px" @close="remarkClose">
			<div style="padding: 30px 0;text-align: center;">
				确定确认回寄收货后，本次维权结束
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="shouhuoDialogVisible=false">关 闭</el-button>
				<el-button type="primary" @click="shouhuoMark()">确 认</el-button>
			</span>
		</el-dialog>

		<el-dialog :visible.sync="imgZoomShow">
			<el-carousel v-if="imgZoomShow" height='600px' :initial-index='initialIndex' arrow="always" :autoplay='false'>
				<el-carousel-item v-for="item in imgList" :key="item">
					<img style="width:100%;height:100%;object-fit:contain" :src="item">
				</el-carousel-item>
			</el-carousel>
		</el-dialog>
		
		<!-- 售后备注弹框 -->
		<el-dialog title="售后备注" :visible.sync="ordermarkVisible" width="650px" @close="orderForm.orderMark = ''">
			<el-form :model="orderForm" ref="orderForm" :rules="rules">
				<el-form-item label="售后备注 :" label-width="120px" prop="orderMark">
					<el-input v-model="orderForm.orderMark" maxlength="200" type="textarea" style="width:300px;" clearable></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="ordermarkVisible=false">关 闭</el-button>
				<el-button type="primary" @click="Ordermark('orderForm')" :loading="loading">确 认</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import apiList from '@/api/other'
	import config from '@/config/index'
	import selectProduce from './selectMultiPro.vue'
	import {
		afterSaleremark,
		afterSaleremarklist,
	} from '@/api/wyUsedInterface'
	var timer;
	var durationTime;
	export default {
		components: {
			selectProduce
		},
		data() {
			return {
				imgZoomShow: false,
				initialIndex: 0,
				imgList: [],
				imgUrl: config.IMG_BASE,
				loading: false,
				ruleForm: null,
				currentId: '',
				expressInfo: {},
				active: 1,
				ordermarkDialogVisible: false,
				orderMark: '',
				addressList: [],
				radio: 1,
				authType: 1, //审核换货 是否同意  1 拒绝 2 同意
				addressDialogVisible: false,
				choseAddress: null,
				selectProDialog: false,
				selectProduct: [],
				shouhuoDialogVisible: false,
				durationDesc: '00天00时00分00秒',
				orderRemark: [], //售后备注
				orderForm: {
					orderMark: '', //备注弹窗内容
				},
				ordermarkVisible: false, //售后备注
				rules: {
					orderMark: [{
						required: true,
						message: '请填写备注内容',
						trigger: 'blur',
					}, ],
				},
			}
		},
		created() {
			this.currentId = this.$route.query.id
			this.getList();
			this.getOrdermark();
		},
		methods: {
			async getList() {
				let that = this;
				clearInterval(timer);
				console.log(123);
				try {
					this.loading = true
					let result = await apiList.ordersupportDetail({
						afterSaleId: this.currentId
					})
					if (result.success) {
						// result.data.type = 3;
						// result.data.status = 4;
						if (result.data.type == 3) {
							if (result.data.status == 1) {
								this.active = 1;
							} else if (result.data.status == 2 || result.data.status == 3) {
								this.active = 2;
							} else if (result.data.status == 4 || result.data.status == 5) {
								this.active = 3;
							} else if (result.data.status == 7 || result.data.status == 8 || result.data.status == 9) {
								this.active = 4;
							}
						}
						durationTime = parseInt(result.data.duration / 1000);
						if (durationTime > 0) {
							timer = setInterval(function() {
								durationTime = durationTime - 1; //函数每调用一次num减一
								that.durationDesc = that.getTime(durationTime);
								if (durationTime == 1) { //当num变为1的时候，通过 clearInterval()结束倒计时
									clearInterval(timer);
								}
							}, 1000);
						}
						this.ruleForm = result.data;
						setTimeout(function() {
							if (result.data.status == 9) {
								that.$router.push({
									path: '/order/refundAfterSale/refundCreateOrder?id=' + that.currentId
								});
							}
						}, 2000)
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}

				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			getTime(time) {
				var day = parseInt(time / 60 / 60 / 24);
				var hour = parseInt(time / 60 / 60 % 24);
				var minute = parseInt(time / 60 % 60);
				var second = parseInt(time % 60);
				return (day < 10 ? '0' + day + '天' : day + '天') + (hour < 10 ? '0' + hour + '时' : hour + '时') + (
					minute < 10 ? '0' + minute + '分' : minute + '分') + (second < 10 ? '0' + second + '秒' : second + '秒');
			},
			remarkClose() {
				this.ordermarkDialogVisible = false;
			},

			//换货申请审核
			sureApply(type) {
				this.authType = type;
				this.orderMark = "";
				if (this.authType == 2) {
					this.sureOrdermark();
				} else {
					this.ordermarkDialogVisible = true;
				}
			},
			//换货申请审核
			async sureOrdermark() {
				this.ordermarkDialogVisible = false;
				try {
					let res = await apiList.ordersupportRefuseapply({
						afterSaleId: this.currentId,
						isAgree: this.authType == 2,
						refuseReason: this.orderMark
					})
					if (res.success) {
						
						if(this.authType==2){
							this.$message({
								showClose: true,
								type: 'success',
								message: '换货申请成功'
							});
						}else{
							this.$message({
								showClose: true,
								type: 'success',
								message: '拒绝换货申请成功'
							});
						}
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg ? res.alertMsg : ''
						});
					}
					this.getList();
				} catch (e) {
					//TODO handle the exception
				}
			},
			//是否同意退货货
			customerSureApply() {

			},
			async showAddressChose() {
				let res = await apiList.deliveryAddressList({});
				if (res.success) {
					this.addressList = res.data || [];
					if (this.addressList.length) {
						this.addressList.map((item, index) => {
							if (this.ruleForm.addressId == item.id) {
								this.radio = index + 1;
								this.choseAddress = item;
							}
							return item;
						})
					}
					this.addressDialogVisible = true;
				}
			},
			addressMark() {
				var index = +this.radio;
				this.choseAddress = this.addressList[index - 1];
				this.ruleForm.receiverName = this.choseAddress.receiverName;
				this.ruleForm.receiverPhone = this.choseAddress.receiverPhone;
				this.ruleForm.addressCode = this.choseAddress.addressCode;
				this.ruleForm.address = this.choseAddress.province + this.choseAddress.city + this.choseAddress.area + this.choseAddress
					.detailAddress;
				this.ruleForm.addressId = this.choseAddress.id;
				this.addressDialogVisible = false;
			},
			//确认发送地址
			async sureDelivery() {
				let data = {
					afterSaleId: this.currentId,
					isAgree: true,
					addressId: this.ruleForm.addressId || 0
				}
				let res = await apiList.deliverysConfirm(data);
				if (res.success) {
					this.getList();
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg ? res.alertMsg : ''
					});
				}
			},
			//确认收货
			sureShouhuo() {
				this.shouhuoDialogVisible = true;
			},
			toEditAddress() {
				this.$router.push({
					path: '/setting/businessAddress/index'
				});
			},
			//确认收货
			async shouhuoMark() {
				let data = {
					afterSaleId: this.currentId,
					isAgree: true
				}
				let res = await apiList.deliveryShouhuo(data);
				if (res.success) {
					this.$router.push({
						path: '/order/refundAfterSale/refundCreateOrder?id=' + this.currentId
					});
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg ? res.alertMsg : '确认收货失败'
					});
					this.getList();
				}
			},
			//放大图片
			handleChangeImg(imgList, index) {
				this.imgList = imgList
				this.initialIndex = index
				this.imgZoomShow = true
            },
            toDetial(){
                let params = {
                    Id : this.ruleForm.orderId
                }
                this.publicToDetails(params,'/order/orderDetailpage',this);
            },
			// 确认订单备注
			async Ordermark(formName) {
				var mark = this.orderForm.orderMark.replace(' ','');
				if(!mark){
					this.$message({
						showClose: true,
						type: 'error',
						message: '备注不能为空!'
					});
					return;
				}
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							let data = {
								afterSaleId: this.currentId,
								remark: this.orderForm.orderMark
							}
							let result = await afterSaleremark(data)
							if (result.success) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.ordermarkVisible = false;
								this.getOrdermark();
							}else{
								this.$message({
									showClose: true,
									type: 'error',
									message: result.alertMsg
								});
							}
						} catch (error) {
							console.log(error)
						} finally {
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 获取订单备注
			async getOrdermark() {
				try {
					let data = {
						afterSaleId: this.currentId,
					}
					let result = await afterSaleremarklist(data)
					if (result.success) {
						this.orderRemark = result.data;
					}
				} catch (error) {
					console.log(error)
				}
			},
		}
	}
</script>
<style lang="less" scoped>
	.refund-top {
		background-color: #fff;
		padding: 0 10px 0 10px;
		overflow: hidden;

		.refund-number {
			display: flex;
			flex-direction: row;
			line-height: 60px;

			.refund-status {
				// margin-left: 430px;
			}

			.lable {
				font-size: 14px;
			}

			.text {
				font-size: 18px;
				margin-left: 10px;
			}
		}

		.refund-remark {
			font-size: 14px;
			padding: 0 10px;
			width: 100%;
			line-height: 50px;
			// height: 50px;
			border: 2px solid #409EFF;
			background-color: #D9ECFF;
			margin-bottom: 20px;

			.refund-img {
				border: 1px solid #ddd;
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 5px;
			}

		}

		.refund-remark-two {
			font-size: 14px;
			padding: 10px 10px;
			width: 100%;
			line-height: 30px;
			border: 2px solid #409EFF;
			background-color: #D9ECFF;
			margin-bottom: 20px;

			.refund-img {
				border: 1px solid #ddd;
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 5px;
			}
		}
	}

	.refund-message {
		background-color: #fff;
		margin-top: 10px;
		padding: 20px;
		overflow: hidden;

		.title {
			font-weight: bold;
			margin-bottom: 15px;
		}

		.item {
			font-size: 14px;
			margin: 10px 0;
			display: flex;
			flex-direction: row;
		}

		.text {
			width: 230px;
			overflow: hidden;
		}

		.img {
			border: 1px solid #ddd;
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 5px;
		}

		.refund-message-left {
			width: 50%;
			float: left;
		}

		.refund-message-right {
			width: 50%;
			float: right;

			.number {
				color: #409EFF;
				cursor: pointer;
			}
		}
	}

	.refund-table {
		margin-top: 10px;

		.dialog-name-content {
			display: flex;
			flex-direction: row;

			img {
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 10px;
				border: 1px solid #ddd;
			}

			.name {
				font-size: 14px;
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			span {
				font-size: 12px;
			}
		}
	}

	// .el-carousel__item.is-active{
	//   text-align: center;
	// }
	.refund-record {
		background-color: #fff;
		padding: 20px;
		margin-top: 40px;
		border-top: 1px solid #f5f5f5;
		overflow: hidden;

		.title {
			font-weight: bold;
		}

		.refund-timeline {
			margin-top: 20px;
			width: 800px;

			/deep/.el-timeline {
				padding-left: 10px !important;
			}
		}
	}

	.product-info {
		padding: 15px 20px 0 20px;
	}
</style>
